<template>
  <div>
    <CRow>
      <CCol md="8">
        <CCard>
          <CCardBody class="p-4">
            <h1>FAQ</h1>

            <div class="mt-1">
              <CCard class="mb-0" v-for="(item, index) in faqList" :key="index">
                <CButton
                  block
                  color="link"
                  class="text-left shadow-none card-header"
                  @click="accordion = accordion === index ? false : index"
                >
                  <h5 class="m-0">{{ item.name }}</h5>
                </CButton>
                <CCollapse :show="accordion === index">
                  <CCardBody>
                    {{ item.details }}
                  </CCardBody>
                </CCollapse>
              </CCard>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import faq from "@/api/faq";
export default {
  data() {
    return {
      accordion: 0,
      faqList: [],
    };
  },
  watch: {
    $route() {
      this.loadFaq();
    },
  },
  mounted() {
    this.loadFaq();
  },
  methods: {
    loadFaq() {
      faq
        .getFaq()
        .then((data) => {
          this.faqList = data.data;
        })
        .catch(() => {});
    },
  },
};
</script>